import { Injectable } from '@angular/core';

@Injectable()
export class RedirectService {
  constructor() { }
  post(obj, url) {
    var mapForm = document.createElement("form");
    mapForm.target = "_blank";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", typeof obj[param] === 'object' ? JSON.stringify(obj[param]) : obj[param]);
      mapForm.appendChild(mapInput);
    });

    document.body.appendChild(mapForm);
    mapForm.submit();
    // Also remove the form created once everything runs
    document.body.removeChild(mapForm);
  }



}


