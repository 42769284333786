import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticeComponent } from './notice/notice.component';
import { CustomChatComponent } from './custom-chat/custom-chat.component';
import { SavedStatusComponent } from './saved-status/saved-status.component';
import { FormsModule } from '@angular/forms';
import { RecordInfoComponent } from './record-info/record-info.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],


  declarations: [NoticeComponent, SavedStatusComponent, CustomChatComponent, RecordInfoComponent],
  exports: [NoticeComponent, SavedStatusComponent, CustomChatComponent, RecordInfoComponent],
})
export class UiComponentsModule { }
