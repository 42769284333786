import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromAuth from '../store/auth';
import { AuthService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<fromAuth.AuthState>, private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (state.url.includes("?from=RegTek") && !localStorage.getItem("urlFromRegTek")) {
      localStorage.setItem("urlFromRegTek", state.url.substring(0, state.url.indexOf('?')))
    }

    if (this.authService.user) {
      return true;
    }
    else {
      this.store.dispatch(new fromAuth.LoginRedirect('/main'));
      return false;
    }

  }
}
