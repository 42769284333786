import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { environment } from '@env/environment';
import { Observable, throwError } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { AuthTokenService } from "./auth-token.service";
import { ContentTypeEnum } from "@app/shared/enumerations/ContentTypeEnum";
import { HelperFunctionsService } from "./helperFunctions.service";
import { I18nService } from "@app/shared/i18n/i18n.service";
import { AuthService } from "./auth.service";


@Injectable()
export class ApiService {
  private cachedData: any[] = [];

  constructor(
    private httpClient: HttpClient, 
    public authToken: AuthTokenService,
    // public i18nService: I18nService
    ) { }

  private getHeaders(contentType: ContentTypeEnum, addBearer: boolean = false) { 
    if (!addBearer) {
      return new HttpHeaders(
        {
          "Content-Type": contentType.toString(),
          "Accept-Language": this.getLanguageCode()
        }
      );
    } else {
      return new HttpHeaders(
        {
          "Content-Type": contentType.toString(),
          "Authorization": `Bearer ${this.authToken.token}`,
          "Accept-Language": this.getLanguageCode()
        }
      );
    }
  }

  private fetch(url): Observable<any> {
    const httpOptions = {
      headers: this.getHeaders(ContentTypeEnum.FormUrlencoded)
    };
    return this.httpClient.get(environment.URL + url, httpOptions).pipe(
      map((data: any) =>
        data.data || data),
      catchError(e => {
        return this.handleError(e, url);
      })
    );
  }

  private fetchBase(url): Observable<any> {
    const httpOptions = {
      headers: this.getHeaders(ContentTypeEnum.FormUrlencoded)
    };
    return this.httpClient.get(environment.BASE_URL + url, httpOptions).pipe(
      map((data: any) => data.data || data),
      catchError(e => {
        return this.handleError(e, url);
      })
    );
  }

  private post(url, body): Observable<any> {
    const httpOptions = {
      headers: this.getHeaders(ContentTypeEnum.FormUrlencoded)
    };
    return this.httpClient.post(environment.URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => (data.data || data)),
        catchError(e => {
          return this.handleError(e, url);
        })
      )
  }

  private postWithOutBody(url): Observable<any> {
    const httpOptions = {
      headers: this.getHeaders(ContentTypeEnum.FormUrlencoded)
    };
    return this.httpClient.post(environment.URL + url, null, httpOptions).pipe(
      map((data: any) =>
        data.data || data),
      catchError(e => {
        return this.handleError(e, url);
      })
    );
  }

  private delete(url, body): Observable<any> {
    const httpOptions = {
      headers: this.getHeaders(ContentTypeEnum.FormUrlencoded), body: body
    };
    return this.httpClient.delete(environment.URL + url, httpOptions)
      .pipe(
        map((data: any) => (data.data || data)),
        catchError(e => {
          return this.handleError(e, url);
        })
      )
  }

  private postBase(url, body): Observable<any> {
    const httpOptions = {
      headers: this.getHeaders(ContentTypeEnum.FormUrlencoded)
    };
    return this.httpClient
      .post(environment.BASE_URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => data.data || data),
        catchError(e => {
          return this.handleError(e, url);
        })
      );
  }

  private postForm(url, body): Observable<any> {
    return this.httpClient.post(environment.URL + url, body).pipe(
      map((data: any) => data.data || data),
      catchError(e => {
        return this.handleError(e, url);
      })
    );
  }

  private postJson(url, body): Observable<any> {
    const httpOptions = {
      headers: this.getHeaders(ContentTypeEnum.Json)
    };
    return this.httpClient.post(environment.URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => (data.data || data)),
        catchError(e => {
          return this.handleError(e, url);
        })
      )
  }

  public putJson(url, body): Observable<any> {
    const httpOptions = {
      headers: this.getHeaders(ContentTypeEnum.Json)
    };
    return this.httpClient.put(environment.URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => (data.data || data)),
        catchError(e => {
          return this.handleError(e, url);
        })
      )
  }

  private deleteJson(url, body): Observable<any> {
    const httpOptions = {
      headers: this.getHeaders(ContentTypeEnum.Json), body: body
    };
    return this.httpClient.delete(environment.URL + url, httpOptions)
      .pipe(
        map((data: any) => (data.data || data)),
        catchError(e => {
          return this.handleError(e, url);
        })
      )
  }

  public putForm(url, body): Observable<any> {
    return this.httpClient.put(environment.URL + url, body).pipe(
      map((data: any) => data.data || data),
      catchError(e => {
        return this.handleError(e, url);
      })
    );
  }

  public getAspNetDataStore(key: any, method: string, endpoint: string, body: any = null) {
    return AspNetData.createStore({
      key: key,
      loadMethod: method,
      loadUrl: body != null ? `${environment.URL}/${endpoint}?customFilters=` + JSON.stringify(body) : `${environment.URL}/${endpoint}`,
      deleteUrl: `${environment.URL}/paging/devExtremeDummyUrl`,
      updateUrl: `${environment.URL}/paging/devExtremeDummyUrl`,
      onBeforeSend: (_, httpOptions) => {
        httpOptions.headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `Bearer ${this.authToken.token}`,
          "Accept-Language": this.getLanguageCode()
        }
      },
    });
  }

  public getUser(): Observable<any> {
    return this.fetch("/user/currentuser");
  }

  public getNotifications(): Observable<any> {
    return this.fetch("/user/notifications");
  }

  public updateNotificationIsRead(id: any): Observable<any> {
    let body = {
      "id": id
    }

    return this.putJson("/user/updateNotificationIsRead", JSON.stringify(body));
  }

  public updateAllNotificationsIsRead(isRead: boolean): Observable<any> {
    let body = {
      "isRead": isRead
    }
    return this.putJson("/user/updateAllNotificationsIsRead", JSON.stringify(body));
  }

  public checkContactToEmail(envEmail: string): Observable<any> {

    let body = {
      'contactToEmail': envEmail
    };

    return this.postJson("/user/checkContactToEmail", JSON.stringify(body));

  }

  public getPortalDynamicRegistrationFieldsByUser(): Observable<any> {
    return this.fetch("/user/dynamicRegistrationFields");
  }

  public getPortalQuestionnaireTypes(): Observable<any> {
    return this.getCachedData("/portal/questionnaireTypes");
  }

  public getClientTypeByClientRegistrationPhaseId(clientRegistrationPhaseId): Observable<any> {
    return this.getCachedData(`/portal/${clientRegistrationPhaseId}/clientType`);
  }

  public editPortalDynamicRegistrationFieldAnswer(json: any): Observable<any> {
    let body = {
      "userProfileAnswers": json
    };
    return this.putJson("/user/dynamicRegistrationFieldAnswer", JSON.stringify(body));
  }

  public getUserByUsername(username: string): Observable<any> {
    return this.fetch("/administration/users/" + username);
  }

  public getUsers(): Observable<any> {
    return this.fetch("/administration/users");
  }

  public getAccountsDetails(): Observable<any> {
    return this.fetch("/administration/accountsDetails");
  }

  public restoreUserPersmissions(username: string): Observable<any> {
    let body = {
      "username": username
    }
    return this.postJson("/administration/restoreUserPermissions", JSON.stringify(body));
  }

  public getAccountStatusEnum(): Observable<any> {
    return this.fetch("/enumerations/accountStatusEnum");
  }

  public getAmendmentRequestResponseTypeEnum(): Observable<any> {
    return this.fetch("/enumerations/amendmentRequestResponseTypeEnum");
  }

  public getSystemAccount(): Observable<any> {
    return this.fetch("/systemSettings/systemAccounts");
  }

  public getAppVersion(): Observable<any> {

    return this.fetch("/user/getAppVersion");
  }

  public getEmailStatusEnum(): Observable<any> {
    return this.fetch("/enumerations/emailStatusEnum");
  }

  public getScheduleIntervalsEnum(): Observable<any> {
    return this.fetch("/enumerations/scheduleIntervalsEnum");
  }

  public getStatusEnum(): Observable<any> {
    return this.fetch("/enumerations/statusEnum");
  }

  public getScheduleStatusEnum(): Observable<any> {
    return this.fetch("/enumerations/scheduleStatusEnum");
  }

  public getRoleById(id: string): Observable<any> {
    return this.fetch("/administration/roles/" + id);
  }

  public getResetPasswordToken(email: string): Observable<any> {
    let body = new URLSearchParams();
    body.set("Email", email);
    return this.postBase("/authorization/getResetPasswordToken", body);
  }

  public getUserMobileNumber(username: any): Observable<any> {
    let body = {
      "UserName": username
    };

    return this.postJson("/user/userMobileNumber", JSON.stringify(body));
  }

  public editUserMobileNumber(userName: any, mobileNumber: any): Observable<any> {
    let body = {
      "UserName": userName,
      "PhoneNumber": mobileNumber
    };

    return this.putJson("/user/userMobileNumber", JSON.stringify(body));
  }

  public editUserIkycCredentials(clientId, clientSecret): Observable<any> {
    let body = {
      "clientId": clientId,
      "clientSecret": clientSecret
    };

    return this.putJson("/user/ikycCredentials", JSON.stringify(body));
  }

  public register(registerForm: FormGroup, contactToEmail: any, additionalData: any): Observable<any> {
    let body = new URLSearchParams();
    body.set("email", registerForm.controls.email.value);
    body.set("password", registerForm.controls.password.value);
    body.set("phoneNumber", registerForm.controls.mobileNumber.value);
    body.set("confirmPassword", registerForm.controls.confirmPassword.value);
    body.set("contactToEmail", contactToEmail);
    body.set("additionalDataStr", additionalData);

    return this.postBase("/authorization/register", body);
  }

  public confirmEmail(email: string, token: string): Observable<any> {
    let body = new URLSearchParams();
    body.set("email", email);
    body.set("token", token);

    return this.postBase("/authorization/confirmEmail", body);
  }

  public resetPassword(resetPasswordForm: FormGroup, email: string, token: string): Observable<any> {
    let body = new URLSearchParams();
    body.set("Email", email);
    body.set("Token", token);
    body.set("NewPassword", resetPasswordForm.controls.newPassword.value);
    body.set("ConfirmPassword", resetPasswordForm.controls.confirmPassword.value);
    return this.postBase("/authorization/resetPassword", body);
  }

  public editAccount(editAccountForm: FormGroup, editAccountSettingsForm: FormGroup, files: any): Observable<any> {

    let body = new FormData();
    var imageFile = files[0];

    body.append("Id", editAccountForm.controls.id.value);
    body.append("Name", editAccountForm.controls.name.value);
    body.append("DisplayName", editAccountForm.controls.displayName.value);
    body.append("ContactName", editAccountForm.controls.contactName.value);
    body.append("ContactPhone", editAccountForm.controls.contactPhone.value);
    body.append("ContactEmail", editAccountForm.controls.contactEmail.value);
    body.append("AddressLine1", editAccountForm.controls.addressLine1.value);
    body.append("AddressLine2", editAccountForm.controls.addressLine2.value);
    body.append("Town", editAccountForm.controls.town.value);
    body.append("PostalCode", editAccountForm.controls.postalCode.value);
    body.append("POBox", editAccountForm.controls.poBox.value);
    body.append("Country", editAccountForm.controls.country.value);
    body.append("Logo", imageFile);
    body.append("UboSharePercThreshold", editAccountSettingsForm.controls.uboSharePercThreshold.value);
    body.append("SharePercentageIncludedInEvaluation", editAccountSettingsForm.controls.sharePercentageIncludedInEvaluation.value);
    return this.putForm("/administration/accounts", body);
  }

  public editRole(editRoleForm: FormGroup): Observable<any> {
    let body = {
      "Id": editRoleForm.controls.id.value,
      "Name": editRoleForm.controls.name.value,
      "Description": editRoleForm.controls.description.value
    }

    return this.putJson("/administration/roles", JSON.stringify(body));
  }

  public editUser(editUserForm: FormGroup): Observable<any> {
    let body = {
      "Email": editUserForm.controls.email.value,
      "Role": editUserForm.controls.role.value,
      "IsActive": editUserForm.controls.isActive.value,
      "Username": editUserForm.controls.username.value,
      "FirstName": editUserForm.controls.firstName.value,
      "LastName": editUserForm.controls.lastName.value,
    }
    return this.putJson("/administration/users", JSON.stringify(body));
  }

  public deleteUser(username: string): Observable<any> {
    return this.delete("/administration/users/" + username, '');
  }

  public addUser(addUserForm: FormGroup): Observable<any> {
    let body = {
      "userName": addUserForm.controls.username.value,
      "firstName": addUserForm.controls.firstName.value,
      "lastName": addUserForm.controls.lastName.value,
      "email": addUserForm.controls.email.value,
      "password": addUserForm.controls.password.value,
      "role": addUserForm.controls.role.value,
    }
    return this.postJson("/administration/users", JSON.stringify(body));
  }

  public editRoleClaims(role: any): Observable<any> {
    let body = {
      "Id": role.id,
      "Name": role.name,
      "Claims": role.claims
    }

    return this.putJson("/administration/roles/" + role.id + "/claims", JSON.stringify(body));
  }

  public addRole(addRoleForm: FormGroup): Observable<any> {
    let body = {
      "Name": addRoleForm.controls.name.value,
      "Description": addRoleForm.controls.description.value
    }
    return this.postJson("/administration/roles", JSON.stringify(body));
  }

  public duplicateRole(duplicateRoleForm: FormGroup): Observable<any> {
    let body = {
      "ExistingRoleId": duplicateRoleForm.controls.existingRoleId.value,
      "NewName": duplicateRoleForm.controls.name.value,
      "NewDescription": duplicateRoleForm.controls.description.value
    }
    return this.postJson("/administration/duplicateRole", JSON.stringify(body));
  }

  public changePassword(settingsForm: FormGroup): Observable<any> {
    let body = new URLSearchParams();
    body.set("CurrentPassword", settingsForm.controls.currentPassword.value);
    body.set("NewPassword", settingsForm.controls.newPassword.value);
    body.set("ConfirmPassword", settingsForm.controls.confirmPassword.value);

    return this.post("/user/changePassword", body);
  }

  private handleError(error: any, url: string) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    // let errMsg = (error.error) ? error.error.message :
    //   error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(`${url}: ${error}`); // log to console instead
    return throwError(error);
    //return Observable.throw(errMsg);
  }

  public schedulerAddRuleJob(ruleId: string): Observable<any> {
    return this.post('/scheduler/' + ruleId, "");
  }

  public schedulerDeleteRuleJob(ruleId: string): Observable<any> {
    return this.delete('/scheduler/' + ruleId, "");
  }

  public getPortalSections(regulationGroupId: any, clientType: any, capacityId, portalQuestionnaireTypeId, clientRegistrationPhaseId): Observable<any> {
    capacityId = capacityId == null ? -1 : capacityId;
    portalQuestionnaireTypeId = portalQuestionnaireTypeId == null ? -1 : portalQuestionnaireTypeId;

    return this.fetch(`/portal/clientRegistrationPhases/${clientRegistrationPhaseId}/regulationGroups/${regulationGroupId}/clientTypes/${clientType}/capacities/${capacityId}/portalQuestionnaireTypes/${portalQuestionnaireTypeId}/sections`);
  }

  public getPortalSectionGroups(regulationGroupId: any, clientType: any, capacityId, portalQuestionnaireTypeId): Observable<any> {
    capacityId = capacityId == null ? -1 : capacityId;
    portalQuestionnaireTypeId = portalQuestionnaireTypeId == null ? -1 : portalQuestionnaireTypeId;

    return this.fetch(`/portal/sections/regulationGroups/${regulationGroupId}/clientTypes/${clientType}/capacities/${capacityId}/portalQuestionnaireTypes/${portalQuestionnaireTypeId}/groups`);
  }

  public getFatcaStatuses(): Observable<any> {
    return this.fetch("/portal/fatcaStatuses");
  }

  public getUniqueReferenceNumber(): Observable<any> {
    return this.fetch("/portal/referenceUniqueNumber");
  }

  public getFatcaEntityCategorizations(): Observable<any> {
    return this.fetch("/portal/fatcaEntityCategorizations");
  }

  public getFatcaDocuments(): Observable<any> {
    return this.fetch("/portal/fatcaDocuments");
  }

  public getFatcaCrsEntityCategorizations(): Observable<any> {
    return this.fetch("/portal/fatcaCrsEntityCategorizations");
  }

  public getAdminAvailablePhases(clientType, regulationGroupId, portalQuestionnaireTypeId, capacityId): Observable<any> {
    let body = {
      "clientType": clientType,
      "regulationGroupId": regulationGroupId,
      "portalQuestionnaireTypeId": portalQuestionnaireTypeId,
      "capacityId": capacityId
    };

    return this.postJson("/portal/adminAvailablePhases", JSON.stringify(body));
  }

  public sendSmsOtp(mobile: any): Observable<any> {
    let body = {
      "MobileNumber": mobile
    };

    return this.postJson("/portal/sendSmsOtp", JSON.stringify(body));
  }


  public getIkycUserDashboard(): Observable<any> {
    return this.fetch("/arrangements/ikycUserDashboard");
  }

  public validateSmsOtp(mobile: any, code: any): Observable<any> {
    let body = {
      "Code": code,
      "MobileNumber": mobile
    };

    return this.postJson("/portal/validateSmsOtpCode", JSON.stringify(body));
  }
  public getInitialPhase(regulationGroupId: any, clientType: any): Observable<any> {
    return this.fetch(`/portal/initialPhase/${regulationGroupId}/${clientType}`);
  }

  public getSectionPaymentMethodsSetup(sectionId: any, clientRegistrationId: any): Observable<any> {
    return this.fetch(`/portal/paymentMethodsSetup/${sectionId}/${clientRegistrationId}`);
  }

  public getTimeZones(): Observable<any> {
    return this.fetch("/portal/timeZones");
  }

  public getPortalRegulationGroups(): Observable<any> {
    return this.getCachedData("/portal/regulationGroups");
  }

  public getPortalCaptions(): Observable<any> {
    return this.getCachedData("/portal/captions")
  }

  public getPortalAccount(): Observable<any> {
    return this.getCachedData("/portal/portalAccount")
  }

  public getGuestPortalAccount(): Observable<any> {
    return this.getCachedData("/guest/portalGuestAccount");
  }

  public getGuestPortalUserRegistrationFields(): Observable<any> {
    return this.fetch("/guest/portalUserRegistrationFields");
  }

  public getPortalCapacities(includeCapacitiesWithoutSetup = false): Observable<any> {
    return this.getCachedData(`/portal/capacities/${includeCapacitiesWithoutSetup}`);

  }

  public getIntegrationCapacities(): Observable<any> {
    return this.getCachedData("/portal/integrationCapacities");
  }

  public getMandatoryDocumentsCategories(): Observable<any> {
    return this.getCachedData("/portal/mandatoryDocumentsCategories");
  }

  public getCountries(): Observable<any> {
    return this.getCachedData("/portal/countries");
  }

  public getCountriesGuest(): Observable<any> {
    return this.getCachedData("/guest/countries");
  }

  public getExternalAuthenticationTokenDetails(token: string): Observable<any> {
    return this.fetch(`/guest/externalAuthTokenDetails/${token}`);
  }

  public getClientRegistrationPhaseExternalDetails(clientRegistrationPhaseId): Observable<any> {
    return this.fetch(`/portal/clientRegistrationPhaseExternalDetails/${clientRegistrationPhaseId}`);
  }

  public getBanks(): Observable<any> {
    return this.fetch("/portal/banks");
  }

  public getAmendmentRequestTypes(portalQuestionnaireId: any): Observable<any> {
    return this.fetch("/portal/amendmentRequestTypes/" + portalQuestionnaireId);
  }

  public getRelatedWebsiteTypes(): Observable<any> {
    return this.fetch("/portal/relatedWebsiteTypes");
  }

  public getClientAddressTypesByClientType(clientType: any): Observable<any> {
    return this.getCachedData("/portal/addressesTypesByClientType/" + clientType);
  }

  public getClientInvestmentAccountType(): Observable<any> {
    return this.getCachedData("/portal/investmentAccountTypes")
  }

  public getClientInvestmentAccountStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/investmentAccountStatusEnum");
  }

  public getCurrenciesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/currenciesEnum");
  }

  public getClientRegistrationAddresses(id: any): Observable<any> {
    return this.fetch("/portal/clientsRegistration/" + id.toString() + "/addresses");
  }

  public getClientRegistrationTaxResidency(id: any): Observable<any> {
    return this.fetch("/portal/clientsRegistration/" + id.toString() + "/taxResidency");
  }

  public getReasonsForTin(): Observable<any> {
    return this.getCachedData("/portal/reasonsForTin");
  }

  public getMidTypes(): Observable<any> {
    return this.getCachedData("/portal/midTypes");
  }

  public getMidClasses(): Observable<any> {
    return this.getCachedData("/portal/midClasses");
  }


  public editClientRegistrationTaxResidency(newData: any, oldData: any, clientRegistrationId: any): Observable<any> {
    let body = {
      "id": newData.id != undefined ? newData.id : oldData.id,
      "clientRegistrationId": newData.clientRegistrationId != undefined ? newData.clientRegistrationId : oldData.clientRegistrationId,
      "countryId": newData.countryId != undefined ? newData.countryId : oldData.countryId,
      "tin": newData.tin != undefined ? newData.tin : oldData.tin,
      "notes": newData.notes != undefined ? newData.notes : oldData.notes,
      "reasonForTinId": newData.reasonForTinId != undefined ? newData.reasonForTinId : oldData.reasonForTinId,
      "explanation": newData.explanation != undefined ? newData.explanation : oldData.explanation
    }

    return this.putJson(`/portal/clientsRegistration/${clientRegistrationId}/taxResidency`, JSON.stringify(body));
  }

  public editClientRegistrationFatca(data: any, clientRegistrationId: any): Observable<any> {
    let body = {
      "fatcaStatusId": data.fatcaStatusId,
      "fatcaEntityCategorizationId": data.fatcaEntityCategorizationId,
      "fatcaCrsEntityCategorizationId": data.fatcaCrsEntityCategorizationId,
      "fatcaGiin": data.fatcaGiin,
      "fatcaIndicia": data.fatcaIndicia,
      "clientRegistrationFatcaDocuments": data.clientRegistrationFatcaDocuments
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/fatca", JSON.stringify(body));
  }

  public sendExternalAuthenticationOtp(token, type) {
    let body = {
      "token": token,
      "type": type,
    }

    return this.postJson("/guest/sendExternalAuthOtp", JSON.stringify(body));
  }

  public cancelOtp(token) {
    let body = {
      "token": token
    }

    return this.postJson("/guest/cancelExternalAuthOtp", JSON.stringify(body));
  }

  public editClientRegistrationAddress(newData: any, oldData: any): Observable<any> {
    let body = {
      "id": newData.id != undefined ? newData.id : oldData.id,
      "clientRegistrationId": newData.clientRegistrationId != undefined ? newData.clientRegistrationId : oldData.clientRegistrationId,
      "address": newData.address != undefined ? newData.address : oldData.address,
      "countryId": newData.countryId != undefined ? newData.countryId : oldData.countryId,
      "postalCode": newData.postalCode != undefined ? newData.postalCode : oldData.postalCode,
      "locality": newData.locality != undefined ? newData.locality : oldData.locality,
      "province": newData.province != undefined ? newData.province : oldData.province,
      "district": newData.district != undefined ? newData.district : oldData.district,
      "type": newData.type != undefined ? newData.type : oldData.type
    }
    return this.putJson("/portal/clientsRegistration/" + oldData.clientRegistrationId + "/addresses", JSON.stringify(body));
  }

  public editPaymentMethodSection(clientRegistrationId: any, volumes: any, customFields: any): Observable<any> {
    let body = {
      "volumes": volumes,
      "clientRegistrationId": clientRegistrationId,
      "customFields": customFields
    }
    return this.putJson("/portal/clientsRegistration/" + clientRegistrationId + "/paymentMethods", JSON.stringify(body));
  }

  public requestFormAmendment(clientRegistrationId: any, form: FormGroup, contactToEmail): Observable<any> {
    let body = {
      "amendmentRequestType": form.controls.requestType.value,
      "reasonForAmendment": form.controls.reason.value,
      "contactToEmail": contactToEmail
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/amendmentRequests", JSON.stringify(body));
  }

  public editClientRegistrationUltimateBeneficialOwner(formGroup: FormGroup, clientRegistrationId: any, customFields: any): Observable<any> {
    let body = {
      "id": formGroup.controls.id.value,
      "clientRegistrationId": clientRegistrationId,
      "profileId": formGroup.controls.profileId.value,
      "percentageOfShares": formGroup.controls.percentageOfShares.value,
      "isLegalRepresentative": formGroup.controls.isLegalRepresentative.value,
      "customFields": customFields
    }
    return this.putJson("/portal/clientsRegistration/" + clientRegistrationId + "/ultimateBeneficialOwners", JSON.stringify(body));
  }

  public editClientRegistrationAmendmentRequest(clientRegistrationId: any, amendmentRequestId: any): Observable<any> {
    let body = {
    }
    return this.putJson("/portal/clientsRegistration/" + clientRegistrationId + "/amendmentRequests/" + amendmentRequestId, JSON.stringify(body));
  }

  public editClientRegistrationShareholder(formGroup: FormGroup, clientRegistrationId: any, customFields: any): Observable<any> {
    let body = {
      "id": formGroup.controls.id.value,
      "clientRegistrationId": clientRegistrationId,
      "numberOfShares": formGroup.controls.numberOfShares.value,
      "isLegalRepresentative": formGroup.controls.isLegalRepresentative.value,
      "sameAsUbo": formGroup.controls.sameAsUbo.value,
      "customFields": customFields
    }
    return this.putJson("/portal/clientsRegistration/" + clientRegistrationId + "/shareholders", JSON.stringify(body));
  }

  public editClientRegistrationAuthorizedPerson(formGroup: FormGroup, clientRegistrationId: any, customFields: any): Observable<any> {
    let body = {
      "id": formGroup.controls.id.value,
      "clientRegistrationId": clientRegistrationId,
      "isLegalRepresentative": formGroup.controls.isLegalRepresentative.value,
      "customFields": customFields
    }
    return this.putJson(`/portal/clientsRegistration/${clientRegistrationId}/authorizedPersons/${formGroup.controls.id.value}`, JSON.stringify(body));
  }

  public addClientRegistrationAddress(addAddressForm: FormGroup, clientRegistrationId: string): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "address": addAddressForm.controls.address.value,
      "countryId": addAddressForm.controls.countryId.value,
      "postalCode": addAddressForm.controls.postalCode.value,
      "locality": addAddressForm.controls.locality.value,
      "province": addAddressForm.controls.province.value,
      "district": addAddressForm.controls.district.value,
      "type": addAddressForm.controls.type.value
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/addresses", JSON.stringify(body));
  }

  public addClientRegistrationProgressLog(clientRegistrationPhaseId, sectionId, isCompleted): Observable<any> {
    let body = {
      "clientRegistrationPhaseId": clientRegistrationPhaseId,
      "sectionId": sectionId,
      "isCompleted": isCompleted
    };

    return this.postJson("/portal/clientsRegistration/progress/log", JSON.stringify(body));
  }

  public addBank(data: any): Observable<any> {
    let body = {
      "name": data.name,
      "mappingReference": data.mappingReference,
      "code": data.code,

    }
    return this.postJson("/portal/banks", JSON.stringify(body));
  }

  public generateClientRegistrationLink(data: any): Observable<any> {
    let body = {
      "clientRegistrationPhaseId": data.clientRegistrationPhaseId,
      "email": data.email,
      "phoneNumber": data.phoneNumber,
      "actionType": data.actionType
    }

    return this.postJson("/portal/generateClientRegistrationLink", JSON.stringify(body));
  }

  public addClientRegistrationTaxResidency(addTaxResidencyForm: FormGroup, clientRegistrationId: string): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "countryId": addTaxResidencyForm.controls.countryId.value,
      "tin": addTaxResidencyForm.controls.tin.value,
      "notes": addTaxResidencyForm.controls.notes.value,
      "reasonForTinId": addTaxResidencyForm.controls.reasonForTinId.value,
      "explanation": addTaxResidencyForm.controls.explanation.value
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/taxResidency", JSON.stringify(body));
  }

  public addClientRegistrationUltimateBeneficialOwner(addUltimateBeneficialOwnerForm: FormGroup, clientRegistrationId: string, customFields: any): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "reference": addUltimateBeneficialOwnerForm.controls.reference.value,
      "percentageOfShares": addUltimateBeneficialOwnerForm.controls.percentageOfShares.value,
      "profileId": addUltimateBeneficialOwnerForm.controls.profileId.value,
      "portalQuestionnaireTypeId": addUltimateBeneficialOwnerForm.controls.portalQuestionnaireTypeId.value,
      "isLegalRepresentative": addUltimateBeneficialOwnerForm.controls.isLegalRepresentative.value,
      "customFields": customFields
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/ultimateBeneficialOwners", JSON.stringify(body));
  }

  public addClientRegistrationShareholder(addShareholderForm: FormGroup, clientRegistrationId: string, clientType: number, customFields: any): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "reference": addShareholderForm.controls.reference.value,
      "numberOfShares": addShareholderForm.controls.numberOfShares.value,
      "profileId": addShareholderForm.controls.profileId.value,
      "portalQuestionnaireTypeId": addShareholderForm.controls.portalQuestionnaireTypeId.value,
      "clientType": clientType,
      "isLegalRepresentative": addShareholderForm.controls.isLegalRepresentative.value,
      "sameAsUbo": addShareholderForm.controls.sameAsUbo.value,
      "customFields": customFields
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/shareholders", JSON.stringify(body));
  }

  public addClientRegistrationAuthorizedPerson(addAuthorizedPersonForm: FormGroup, clientRegistrationId: string, clientType: number, customFields: any): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "reference": addAuthorizedPersonForm.controls.reference.value,
      "profileId": addAuthorizedPersonForm.controls.profileId.value,
      "capacityId": addAuthorizedPersonForm.controls.capacityId.value,
      "portalQuestionnaireTypeId": addAuthorizedPersonForm.controls.portalQuestionnaireTypeId.value,
      "clientType": clientType,
      "isLegalRepresentative": addAuthorizedPersonForm.controls.isLegalRepresentative.value,
      "customFields": customFields
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/authorizedPersons", JSON.stringify(body));
  }

  public deleteClientRegistrationAddress(clientRegistrationId: string, addressId: string): Observable<any> {
    return this.delete("/portal/clientsRegistration/" + clientRegistrationId + "/addresses/" + addressId, '');
  }

  public deleteClientRegistrationTaxResidency(clientRegistrationId: string, taxResidencyId: string): Observable<any> {
    return this.delete("/portal/clientsRegistration/" + clientRegistrationId + "/taxResidency/" + taxResidencyId, '');
  }


  public deleteClientRegistrationUltimateBeneficialOwner(clientRegistrationId: string, ClientRegistrationUltimateBeneficialOwnerId: string): Observable<any> {
    return this.delete("/portal/clientsRegistration/" + clientRegistrationId + "/ultimateBeneficialOwners/" + ClientRegistrationUltimateBeneficialOwnerId, '');
  }

  public deleteClientRegistrationShareholder(clientRegistrationId: string, shareholderId: string): Observable<any> {
    return this.delete("/portal/clientsRegistration/" + clientRegistrationId + "/shareholders/" + shareholderId, '');
  }

  public deleteClientRegistrationDocument(clientRegistrationId: string, shareholderId: string): Observable<any> {
    return this.delete("/portal/clientsRegistration/" + clientRegistrationId + "/shareholders/" + shareholderId, '');
  }

  public deleteClientRegistrationAuthorizedPerson(clientRegistrationId: any, authorizedPersonId: any): Observable<any> {
    return this.delete("/portal/clientsRegistration/" + clientRegistrationId + "/authorizedPersons/" + authorizedPersonId, '');
  }

  public getClientTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/clientTypeEnum");
  }

  public getClientRegistrationStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/clientRegistrationStatusEnum");
  }

  public addClientRegistration(addClientAccountForm: FormGroup, isClient: boolean): Observable<any> {
    let body = {
      "regulationGroupId": addClientAccountForm.controls.regulationGroup.value,
      "clientType": addClientAccountForm.controls.clientType.value,
      "reference": addClientAccountForm.controls.reference.value,
      "portalQuestionnaireTypeId": addClientAccountForm.controls.portalQuestionnaireTypeId.value,
      "isClient": isClient,
    }
    return this.postJson("/portal/clientsRegistration", JSON.stringify(body));
  }

  public addPortalQuestionnaireToExistingClient(clientRegistrationId, portalQuestionnaireTypeId): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "portalQuestionnaireTypeId": portalQuestionnaireTypeId
    }
    return this.postJson("/portal/clientRegistrationQuestionnaires", JSON.stringify(body));
  }

  public addArrangement(addArrangementForm: FormGroup): Observable<any> {
    let body = {
      "arrangementName": addArrangementForm.controls.arrangementName.value,
      "taxpayerName": addArrangementForm.controls.taxpayerName.value,
      "reportingImplementationDate": addArrangementForm.controls.reportingImplementationDate.value,
    }
    return this.postJson("/arrangements", JSON.stringify(body));
  }

  public getArrangementById(id: any): Observable<any> {
    return this.fetch(`/arrangements/${id}`);
  }

  public exportDacSixXML(id: any): Observable<any> {
    return this.fetch(`/arrangements/exportXml/${id}`);
  }

  public addClientRegistrationAnswers(clientRegistrationId: any, sectionId: any, sectionAnswersToBeSavedAsDraft: any): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "sectionId": sectionId,
      "clientRegistrationAnswers": sectionAnswersToBeSavedAsDraft
    }
    return this.postJson("/portal/clientRegistrationAnswers", JSON.stringify(body));
  }

  public validateClientRegistration(clientRegistrationPhaseId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationPhaseId}/validate`);
  }

  // Submit client registration from Hangfire
  public submitClientRegistrationJob(clientRegistrationPhaseId: any, contactToEmail: any, userName: any, hasNextPhaseQuery): Observable<any> {
    let body = {
      "clientRegistrationPhaseId": clientRegistrationPhaseId,
      "contactToEmail": contactToEmail,
      "userName": userName,
      "hasNextPhaseQuery": hasNextPhaseQuery
    };

    return this.postJson('/portal/clientsRegistration/submitJob', JSON.stringify(body));
  }

  public submitArrangement(clientRegistrationPhaseId: any, contactToEmail: any): Observable<any> {
    return this.fetch(`/arrangements/${clientRegistrationPhaseId}/submit/${contactToEmail}`);
  }

  public getClientRegistrations(): Observable<any> {
    return this.fetch(`/portal/clientsRegistration`);
  }

  public getArrangements(): Observable<any> {
    return this.fetch(`/arrangements`);
  }

  public getClientRegistrationPhaseStatus(id): Observable<any> {
    return this.fetch(`/portal/clientsRegistrationPhases/${id}/status`);
  }

  public executePhaseValidationQuery(phaseId: any, clientRegistrationPhaseId: any): Observable<any> {
    let body = {
      "phaseId": phaseId,
      "clientRegistrationPhaseId": clientRegistrationPhaseId
    }

    return this.postJson("/portal/validationQuery", JSON.stringify(body));
  }

  public getClientRegistrationFatca(clientRegistrationId): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationId}/fatca`);
  }

  public getClientRegistrationPhaseById(id: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistrationPhases/${id}`);
  }

  public getClientRegistrationUltimateBeneficialOwners(id: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${id}/ultimateBeneficialOwners`);
  }

  public getClientRegistrationUltimateBeneficialOwnerById(id: any, ultimateBeneficialOwnerId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${id}/ultimateBeneficialOwners/${ultimateBeneficialOwnerId}`);
  }

  public getClientRegistrationShareholderById(id: any, shareholderId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${id}/shareholders/${shareholderId}`);
  }

  public getClientAuthorizedPersonById(id: any, authorizedPersonId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${id}/authorizedPersons/${authorizedPersonId}`);
  }

  public getClientRegistrationShareholders(id: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${id}/shareholders`);
  }

  public getClientRegistrationDocuments(clientRegistrationPhaseId): Observable<any> {
    return this.fetch(`/portal/clientsRegistrationPhases/${clientRegistrationPhaseId}/documents`);
  }

  public getClientRegistrationAuthorizedPersons(id: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${id}/authorizedPersons`);
  }

  public getSelectedPortalQuestionnaireTypesByClientRegistrationId(clientRegistrationId: any): Observable<any> {
    return this.fetch(`/portal/selectedPortalQuestionnaireTypes/${clientRegistrationId}`);
  }

  public GetClientsRegistrationAnswersByClientRegistrationId(clientRegistrationId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistrationAnswers/${clientRegistrationId}`);
  }

  public getCollectionStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/collectionStatusEnum");
  }

  public getDocumentVerificationStatus(): Observable<any> {
    return this.getCachedData("/enumerations/documentVerificationStatusEnum");
  }

  public getDocumentFormEnum(): Observable<any> {
    return this.getCachedData("/enumerations/documentFormEnum");
  }

  public getDocumentTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/documentTypeEnum");
  }

  public getClientRegistrationDocumentById(clientRegistrationId: any, clientRegistrationDocumentId: any): Observable<any> {
    return this.fetch("/portal/clientsRegistration/" + clientRegistrationId + "/documents/" + clientRegistrationDocumentId);
  }

  public getClientRegistrationDocumentResults(clientRegistrationId: any, clientRegistrationDocumentId: any): Observable<any> {
    return this.fetch("/portal/clientsRegistration/" + clientRegistrationId + "/documents/" + clientRegistrationDocumentId + "/results");
  }

  public editClientRegistrationDocument(editClientRegistrationDocumentForm: FormGroup, clientRegistrationDocumentId: any, documentId: any, attachments: any[], customFields: any): Observable<any> {

    // Id Document
    if (!isNullOrUndefined(editClientRegistrationDocumentForm.controls.idFrontFileBase64.value) && editClientRegistrationDocumentForm.controls.idFrontFileBase64.value != "") {

      let base64String = editClientRegistrationDocumentForm.controls.idFrontFileBase64.value ? editClientRegistrationDocumentForm.controls.idFrontFileBase64.value : '';

      let base64Data = base64String.split(',')[1];
      let base64Type = base64String.split(',')[0];

      let type = 0;

      if (base64Type.includes('application/pdf')) {
        type = 3
      }
      else if (base64Type.includes('image/jpeg')) {
        type = 2
      }
      else if (base64Type.includes('image/png')) {
        type = 1
      } else {
        type = 1
      }

      let attachment = { "idCardType": "Front", "fileName": editClientRegistrationDocumentForm.controls.idFrontFileName.value, "type": type, "data": base64Data }
      attachments.push(attachment);
    }

    if (!isNullOrUndefined(editClientRegistrationDocumentForm.controls.idBackFileBase64.value) && editClientRegistrationDocumentForm.controls.idBackFileBase64.value != "") {

      let base64String = editClientRegistrationDocumentForm.controls.idBackFileBase64.value ? editClientRegistrationDocumentForm.controls.idBackFileBase64.value : '';

      let base64Data = base64String.split(',')[1];
      let base64Type = base64String.split(',')[0];

      let type = 0;

      if (base64Type.includes('application/pdf')) {
        type = 3
      }
      else if (base64Type.includes('image/jpeg')) {
        type = 2
      }
      else if (base64Type.includes('image/png')) {
        type = 1
      } else {
        type = 1
      }

      let attachment = { "idCardType": "Back", "fileName": editClientRegistrationDocumentForm.controls.idBackFileName.value, "type": type, "data": base64Data }
      attachments.push(attachment);
    }

    if (!isNullOrUndefined(editClientRegistrationDocumentForm.controls.idSelfieFileBase64.value) && editClientRegistrationDocumentForm.controls.idSelfieFileBase64.value != "") {

      let base64String = editClientRegistrationDocumentForm.controls.idSelfieFileBase64.value ? editClientRegistrationDocumentForm.controls.idSelfieFileBase64.value : '';

      let base64Data = base64String.split(',')[1];
      let base64Type = base64String.split(',')[0];

      let type = 0;

      if (base64Type.includes('application/pdf')) {
        type = 3
      }
      else if (base64Type.includes('image/jpeg')) {
        type = 2
      }
      else if (base64Type.includes('image/png')) {
        type = 1
      } else {
        type = 1
      }

      let attachment = { "idCardType": "Selfie", "fileName": editClientRegistrationDocumentForm.controls.idSelfieFileName.value, "type": type, "data": base64Data }
      attachments.push(attachment);
    }

    // Passport Document
    if (!isNullOrUndefined(editClientRegistrationDocumentForm.controls.passportFileBase64.value) && editClientRegistrationDocumentForm.controls.passportFileBase64.value != "") {

      let base64String = editClientRegistrationDocumentForm.controls.passportFileBase64.value ? editClientRegistrationDocumentForm.controls.passportFileBase64.value : '';

      let base64Data = base64String.split(',')[1];
      let base64Type = base64String.split(',')[0];

      let type = 0;

      if (base64Type.includes('application/pdf')) {
        type = 3
      }
      else if (base64Type.includes('image/jpeg')) {
        type = 2
      }
      else if (base64Type.includes('image/png')) {
        type = 1
      } else {
        type = 1
      }

      let attachment = { "fileName": editClientRegistrationDocumentForm.controls.passportFileName.value, "type": type, "data": base64Data }
      attachments.push(attachment);
    }

    if (!isNullOrUndefined(editClientRegistrationDocumentForm.controls.attachmentFileBase64.value) && editClientRegistrationDocumentForm.controls.attachmentFileBase64.value != "") {

      let base64String = editClientRegistrationDocumentForm.controls.attachmentFileBase64.value ? editClientRegistrationDocumentForm.controls.attachmentFileBase64.value : '';

      let base64Data = base64String.split(',')[1];
      let base64Type = base64String.split(',')[0];

      let type = 0;

      if (base64Type.includes('application/pdf')) {
        type = 3
      }
      else if (base64Type.includes('image/jpeg')) {
        type = 2
      }
      else if (base64Type.includes('image/png')) {
        type = 1
      } else {
        type = 1
      }

      let attachment = { "fileName": editClientRegistrationDocumentForm.controls.attachmentFileName.value, "type": type, "data": base64Data }
      attachments.push(attachment);
    }

    // Driving License Document
    if (!isNullOrUndefined(editClientRegistrationDocumentForm.controls.drivingLicenseFrontFileBase64.value) && editClientRegistrationDocumentForm.controls.drivingLicenseFrontFileBase64.value != "") {

      let base64String = editClientRegistrationDocumentForm.controls.drivingLicenseFrontFileBase64.value ? editClientRegistrationDocumentForm.controls.drivingLicenseFrontFileBase64.value : '';

      let base64Data = base64String.split(',')[1];
      let base64Type = base64String.split(',')[0];

      let type = 0;

      if (base64Type.includes('application/pdf')) {
        type = 3
      }
      else if (base64Type.includes('image/jpeg')) {
        type = 2
      }
      else if (base64Type.includes('image/png')) {
        type = 1
      } else {
        type = 1
      }

      let attachment = { "drivingLicenseType": "Front", "fileName": editClientRegistrationDocumentForm.controls.drivingLicenseFrontFileName.value, "type": type, "data": base64Data }
      attachments.push(attachment);
    }

    if (!isNullOrUndefined(editClientRegistrationDocumentForm.controls.drivingLicenseBackFileBase64.value) && editClientRegistrationDocumentForm.controls.drivingLicenseBackFileBase64.value != "") {

      let base64String = editClientRegistrationDocumentForm.controls.drivingLicenseBackFileBase64.value ? editClientRegistrationDocumentForm.controls.drivingLicenseBackFileBase64.value : '';

      let base64Data = base64String.split(',')[1];
      let base64Type = base64String.split(',')[0];

      let type = 0;

      if (base64Type.includes('application/pdf')) {
        type = 3
      }
      else if (base64Type.includes('image/jpeg')) {
        type = 2
      }
      else if (base64Type.includes('image/png')) {
        type = 1
      } else {
        type = 1
      }

      let attachment = { "drivingLicenseType": "Back", "fileName": editClientRegistrationDocumentForm.controls.drivingLicenseBackFileName.value, "type": type, "data": base64Data }
      attachments.push(attachment);
    }

    let body = {
      "id": clientRegistrationDocumentId,
      "documentId": documentId,
      // "collectionStatus": editClientRegistrationDocumentForm.controls.collectionStatusId.value,
      "verificationStatus": editClientRegistrationDocumentForm.controls.verificationStatusId != undefined ? editClientRegistrationDocumentForm.controls.verificationStatusId.value : "",
      "verificationMessage": editClientRegistrationDocumentForm.controls.verificationMessage != undefined ? editClientRegistrationDocumentForm.controls.verificationMessage.value : "",
      "documentForm": editClientRegistrationDocumentForm.controls.documentFormId.value,
      "documentNumber": editClientRegistrationDocumentForm.controls.documentNumber.value,
      "countryOfIssueId": editClientRegistrationDocumentForm.controls.countryOfIssueId.value,
      "dateOfIssue": editClientRegistrationDocumentForm.controls.dateOfIssue.value,
      "expirationDate": editClientRegistrationDocumentForm.controls.expirationDate.value,
      "collectionDate": editClientRegistrationDocumentForm.controls.collectionDate.value,
      "nextCollectionDate": editClientRegistrationDocumentForm.controls.nextCollectionDate.value,
      "certifiedDate": editClientRegistrationDocumentForm.controls.certifiedDate.value,
      "attachments": attachments,
      "mandatory": editClientRegistrationDocumentForm.controls.mandatory.value,
      "documentType": editClientRegistrationDocumentForm.controls.documentTypeId.value,
      "customFields": customFields
    }

    return this.putJson(`/portal/clientsRegistration/documents`, JSON.stringify(body));
  }

  public getClientDocumentAttachmentDataById(id: string, clientDocumentId: string, clientDocumentAttachmentId: string): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${id}/documents/${clientDocumentId}/attachments/${clientDocumentAttachmentId}/data`);
  }

  public getClientDocumentAttachmentNoDataById(id: string, clientDocumentId: string, clientDocumentAttachmentId: string): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${id}/documents/${clientDocumentId}/attachments/${clientDocumentAttachmentId}`);
  }

  public getClientStructureTreeNodes(clientRegistrationId: any, clientRegistrationPhaseId): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationId}/${clientRegistrationPhaseId}/structure`);
  }

  public getClientCategoryByClientRegistrationId(clientRegistrationId, capacityId, portalQuestionnaireTypeId) {
    capacityId = capacityId == null ? -1 : capacityId;
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationId}/capacities/${capacityId}/portalQuestionnaireTypes/${portalQuestionnaireTypeId}/clientCategory`);
  }

  public getClientRegistrationsWithDetails(): Observable<any> {
    return this.fetch("/portal/clientsRegistrationIncludingDetails");
  }

  public getClientRegistrationsWithDetailsByClientRegistrationId(clientRegistrationId): Observable<any> {
    return this.fetch(`/portal/clientsRegistrationIncludingDetails/${clientRegistrationId}`);
  }

  public getClientRegistrationBankAccounts(clientRegistrationId: any): Observable<any> {
    return this.fetch("/portal/clientsRegistration/" + clientRegistrationId + "/bankAccounts");
  }

  public getClientRegistrationInvestmentAccounts(clientRegistrationId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationId}/investmentAccount`);
  }

  public getClientRegistrationMidAccounts(clientRegistrationId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationId}/midAccounts`);
  }

  public getClientRegistrationRelatedWebsites(clientRegistrationId: any): Observable<any> {
    return this.fetch("/portal/clientsRegistration/" + clientRegistrationId + "/relatedWebsites");
  }

  public getClientRegistrationBankAccountById(clientRegistrationId: any, bankAccountId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationId}/bankAccounts/${bankAccountId}`);
  }

  public getClientRegistrationInvestmentAccountById(clientRegistrationId: any, investmentAccountId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationId}/investmentAccount/${investmentAccountId}`);
  }

  public getClientRegistrationMidAccountById(clientRegistrationId: any, midAccountId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationId}/midAccount/${midAccountId}`);
  }

  public getClientRegistrationRelatedWebsiteById(clientRegistrationId: any, relatedWebsiteId: any): Observable<any> {
    return this.fetch(`/portal/clientsRegistration/${clientRegistrationId}/relatedWebsites/${relatedWebsiteId}`);
  }

  public editClientRegistrationBankAccount(form: FormGroup, clientRegistrationId: any, bankAccountId: any): Observable<any> {
    let body = {
      "id": bankAccountId,
      "clientRegistrationId": clientRegistrationId,
      "bankId": form.controls.bankId.value,
      "swiftCode": form.controls.swiftCode.value,
      "countryId": form.controls.countryId.value,
      "iban": form.controls.iban.value,
      "accountNumber": form.controls.accountNumber.value,
      "status": form.controls.status.value,
      "dateCreated": form.controls.dateCreated.value,
      "currency": form.controls.currency.value
    }
    return this.putJson("/portal/clientsRegistration/" + clientRegistrationId + "/bankAccounts/" + bankAccountId, JSON.stringify(body));
  }

  public editClientRegistrationInvestmentAccount(form: FormGroup, clientRegistrationId: any, investmentAccountId: any): Observable<any> {
    let body = {
      "id": investmentAccountId,
      "clientRegistrationId": clientRegistrationId,
      "accountName": form.controls.accountName.value,
      "accountNumber": form.controls.accountNumber.value,
      "accountTypeId": form.controls.accountTypeId.value,
      "accountStatus": form.controls.accountStatus.value,
      "tradingAccount": form.controls.tradingAccount.value,
      "balance": form.controls.balance.value,
      "groupName": form.controls.groupName.value,
      "comment": form.controls.comment.value,
      "previousDayEquity": form.controls.previousDayEquity.value,
      "externalReference": form.controls.externalReference.value,
      "dateCreated": form.controls.dateCreated.value,
      "currency": form.controls.currency.value
    }
    return this.putJson("/portal/clientsRegistration/" + clientRegistrationId + "/investmentAccount/" + investmentAccountId, JSON.stringify(body));
  }

  public editClientRegistrationMidAccount(form: FormGroup, clientRegistrationId: any, midAccountId: any): Observable<any> {
    let body = {
      "id": midAccountId,
      "clientRegistrationId": clientRegistrationId,
      "bankAccountId": form.controls.bankAccountId.value,
      "code": form.controls.code.value,
      "midTypeId": form.controls.midTypeId.value,
      "midClassId": form.controls.midClassId.value,
      "name": form.controls.name.value,
      "currency": form.controls.currency.value
    }
    return this.putJson("/portal/clientsRegistration/" + clientRegistrationId + "/midAccount/" + midAccountId, JSON.stringify(body));
  }

  public editClientRegistrationRelatedWebsite(form: any, clientRegistrationId: any, id: any, customFields: any): Observable<any> {

    let body = {
      "clientRegistrationId": clientRegistrationId,
      "id": id,
      "relatedWebsitetypeId": form.controls.relatedWebsiteTypeId.value,
      "website": form.controls.website.value,
      "customFields": customFields,
    }
    return this.putJson("/portal/clientsRegistration/" + clientRegistrationId + "/relatedWebsites", JSON.stringify(body));
  }

  public addClientRegistrationBankAccount(form: FormGroup, clientRegistrationId: any): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "bankId": form.controls.bankId.value,
      "swiftCode": form.controls.swiftCode.value,
      "countryId": form.controls.countryId.value,
      "iban": form.controls.iban.value,
      "accountNumber": form.controls.accountNumber.value,
      "status": form.controls.status.value,
      "dateCreated": form.controls.dateCreated.value,
      "currency": form.controls.currency.value
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/bankAccounts", JSON.stringify(body));
  }

  public addClientRegistrationCustomProperties(clientRegistrationPhaseId, customVisibilityFields, customEditabilityFields, customVisibilitySections, customVisibilityDocuments, form: FormGroup): Observable<any> {
    let body = {
      "clientRegistrationPhaseId": clientRegistrationPhaseId,
      "customVisibilityFields": customVisibilityFields,
      "customEditabilityFields": customEditabilityFields,
      "customVisibilitySections": customVisibilitySections,
      "customVisibilityDocuments": customVisibilityDocuments,
      "informUser": form.controls.informUserForPhaseChange.value,
      "newPhaseId": form.controls.phase.value
    }

    return this.postJson("/portal/customProperties", JSON.stringify(body));
  }

  public addClientRegistrationInvestmentAccount(form: FormGroup, clientRegistrationId: any): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "accountName": form.controls.accountName.value,
      "accountNumber": form.controls.accountNumber.value,
      "accountTypeId": form.controls.accountType.value,
      "accountStatus": form.controls.accountStatus.value,
      "currency": form.controls.currency.value,
      "tradingAccount": form.controls.tradingAccount.value,
      "dateCreated": form.controls.dateCreated.value,
      "balance": form.controls.balance.value,
      "groupName": form.controls.groupName.value,
      "comment": form.controls.comment.value,
      "previousDayEquity": form.controls.previousDayEquity.value,
      "externalReference": form.controls.externalReference.value
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/investmentAccount", JSON.stringify(body));
  }

  public addClientRegistrationMidAccount(form: FormGroup, clientRegistrationId: any): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "bankAccountId": form.controls.bankAccountId.value,
      "code": form.controls.code.value,
      "midTypeId": form.controls.midTypeId.value,
      "midClassId": form.controls.midClassId.value,
      "name": form.controls.name.value,
      "currency": form.controls.currency.value,
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/midAccount", JSON.stringify(body));
  }

  public addClientRegistrationRelatedWebsite(form: FormGroup, clientRegistrationId: any, customFields: any): Observable<any> {
    let body = {
      "clientRegistrationId": clientRegistrationId,
      "relatedWebsitetypeId": form.controls.relatedWebsitetypeId.value,
      "website": form.controls.website.value,
      "customFields": customFields,
    }
    return this.postJson("/portal/clientsRegistration/" + clientRegistrationId + "/relatedWebsites", JSON.stringify(body));
  }

  public deleteClientRegistrationBankAccount(clientRegistrationId: any, bankAccountId: any): Observable<any> {
    return this.delete(`/portal/clientsRegistration/${clientRegistrationId}/bankAccounts/${bankAccountId}`, '');
  }

  public deleteClientRegistrationInvestmentAccount(clientRegistrationId: any, investmentAccountId: any): Observable<any> {
    return this.delete(`/portal/clientsRegistration/${clientRegistrationId}/investmentAccount/${investmentAccountId}`, '');
  }

  public deleteClientRegistrationMidAccount(clientRegistrationId: any, midAccountId: any): Observable<any> {
    return this.delete(`/portal/clientsRegistration/${clientRegistrationId}/midAccount/${midAccountId}`, '');
  }

  public deleteClientRegistrationRelatedWebsite(clientRegistrationId: any, relatedWebsiteId: any): Observable<any> {
    return this.delete(`/portal/clientsRegistration/${clientRegistrationId}/relatedWebsites/${relatedWebsiteId}`, '');
  }

  public getCustomFieldsByReferenceTable(regulationGroupId: any, referenceTable: any, paymentMethodId: any): Observable<any> {
    return this.fetch(`/portal/customFields/${regulationGroupId}/${referenceTable}/${paymentMethodId}`);
  }

  public updateUserLanguage(language: any): Observable<any> {
    let body = {
      "key": language.key,
      "title": language.title,
      "alt": language.alt,
    }

    return this.putJson(`/user/language`, JSON.stringify(body));
  }

  public updateUserTimezone(timezone: string): Observable<any> {
    let body = {
      "timezone": timezone
    }
    return this.putJson(`/user/timezone`, JSON.stringify(body));
  }

  private getCachedData(endpoint: string) {

    let endpointWithLanguage = endpoint + "/" + this.getLanguageCode();
    if (!this.cachedData.find(x => x.endpoint == endpointWithLanguage)) {
      this.cachedData.push({
        "endpoint": endpointWithLanguage, "data": this.fetch(endpoint).pipe(shareReplay(1))
      });
    }
    return this.cachedData.find(x => x.endpoint == endpointWithLanguage).data;
  }

  public getClientRegistrationAnswersDynamicReport(clientRegistrationId: any): Observable<any> {
    return this.fetch(`/portal/getClientRegistrationAnswers/${clientRegistrationId}`);
  }

  public getPortalDynamicTemplateReport(clientType: any, regulationGroupId: any, questionnaireTypeId: any, capacityId: any): Observable<any> {
    return this.fetch(`/portal/getDynamicTemplate/${clientType}/${regulationGroupId}/${questionnaireTypeId}/${capacityId}`);
  }

  public getPortalCustomQuestionnairesReport(clientRegistrationPhaseId): Observable<any> {
    return this.fetch(`/portal/customQuestionnairesDynamicTemplate/${clientRegistrationPhaseId}`);
  }

  public sendFinalizedReview(clientId: number, clientRegistrationPhaseId: number,
    comments: any, form: any): Observable<any> {
    let body = {
      "ClientId": clientId,
      "ClientRegistrationPhaseId": clientRegistrationPhaseId,
      "Comments": comments,
      "NeedsFurtherInvesigation": form.controls.needFurtherActions.value
    };

    return this.postJson("/portal/finalizeReview", JSON.stringify(body));
  }

  public getClientReviewState(clientRegistrationPhaseId: number): Observable<any> {
    return this.fetch(`/portal/isClientReviewed/${clientRegistrationPhaseId}`);

  }

  public CheckIfCurrentUserIsTaskAssignee(clientId: number): Observable<any> {
    return this.fetch(`/portal/GetUsersTaskAssigneeStatus/${clientId}`);
  }

  public GetWorkflowOnBoardingActiveStatus(): Observable<any> {
    return this.fetch(`/portal/GetWorkflowOnBoardingActiveStatus`);
  }

  public getLanguageFromLocalStorage(): any {
    return localStorage.getItem('language');
  }

  getLanguageCode() { 
    let lan = this.getLanguageFromLocalStorage();
    if (lan === "gr") {
      return "el-GR";
    } else if (lan === "en") {
      return "en-GB";
    } else {
      return "en-GB";
    }  
  }
}
