import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';

import { ApiService } from '../services';

@Injectable()
export class PortalSetupTypeGuard implements CanActivate {
  constructor(private apiService: ApiService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.url.length > 0 && route.url[0]) {
      const path = route.url.map(el => el.path).join('/');

      const isClientOnboarding = path.includes('client');
      const isDAC6 = path.includes('arrangement');
      const isCustomQuestionnaire = path.includes('questionnaires');

      const portalSetupType = isClientOnboarding ? 1 : isDAC6 ? 2 : isCustomQuestionnaire ? 3 : -1;
      return this.apiService.getPortalAccount().pipe(map(portalAccount => portalAccount.portalSetupType == portalSetupType));
    }
    return false;
  }
}
