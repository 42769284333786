import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { delay, tap, map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { FormGroup } from '@angular/forms';

@Injectable()
export class EmailService {

  constructor(
    private httpClient: HttpClient
  ) { }


  public fetch(url): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };
    return this.httpClient.get(environment.URL + url, httpOptions).pipe(
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public post(url, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.httpClient.post(environment.URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        },
          catchError(this.handleError))
      );
  }

  public send(subject: string, message: string, emailTo: string): any {

    let body = new URLSearchParams();
    body.set('code', "123456");
    body.set('email', emailTo);
    body.set('subject', subject);
    body.set('message', message);


    return this.post("/email/send", body);

  }

  public getTemplate(templateName: string): any {

    let body = new URLSearchParams();
    body.set('templateName', templateName);

    return this.post("/email/getTemplate", body);

  }


  private handleError(error: any) {

    console.error(error); // log to console instead
    return throwError(error);
    //return Observable.throw(error);
  }
}
