import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CustomPopOverDirective } from './custom-popover.directive';
import { PortalCaptionDirective } from './portal-caption.directive';

@NgModule({
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
  ],
  declarations: [CustomPopOverDirective, PortalCaptionDirective],
  exports: [CustomPopOverDirective, PortalCaptionDirective]
})
export class DirectivesModule { }
