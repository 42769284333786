import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { SmartadminLayoutModule } from "./layout";

import { I18nModule } from "./i18n/i18n.module";
import { UtilsModule } from "./utils/utils.module";
import { BootstrapModule } from "@app/shared/bootstrap.module";
import { PipesModule } from "./pipes/pipes.module";
// import { UserModule } from "./user/user.module";
// import {VoiceControlModule} from "./voice-control/voice-control.module";

import { SmartadminWidgetsModule } from "./widgets/smartadmin-widgets.module";

// import {ChatModule} from "./chat/chat.module";
import { StatsModule } from "./stats/stats.module";
import { InlineGraphsModule } from "./graphs/inline/inline-graphs.module";
import { SmartadminFormsLiteModule } from "./forms/smartadmin-forms-lite.module";
import { SmartProgressbarModule } from "./ui/smart-progressbar/smart-progressbar.module";
// import { CalendarComponentsModule } from "@app/shared/calendar/calendar-components.module";
import { OrderModule } from 'ngx-order-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { CamelToTextPipe } from "./pipes/camel-to-text.pipe";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { Ng5SliderModule } from 'ng5-slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { UiComponentsModule } from "./ui-components/ui-components.module";
import { DirectivesModule } from "./directives/directives.module";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {
  DxDiagramModule,
  DxPopupModule,
  DxTemplateModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxTreeViewModule,
  DxTabPanelModule,
  DxTreeListModule,
  DxRadioGroupModule,
  DxSelectBoxModule,
  DxContextMenuModule,
  DxTagBoxModule
} from "devextreme-angular";
import { AngularDraggableModule } from 'angular2-draggable';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SmartadminLayoutModule,
    BootstrapModule,
    NgMultiSelectDropDownModule.forRoot(),
    UiComponentsModule,
    NgIdleKeepaliveModule.forRoot(),
    DxDiagramModule,
    DxPopupModule,
    DxTemplateModule,
    DxDataGridModule,
    DxDropDownBoxModule,
    DxTabPanelModule,
    DxTreeViewModule,
    DxRadioGroupModule,
    DxTreeViewModule,
    DxTreeListModule,
    DxSelectBoxModule,
    AngularDraggableModule,
    NgxIntlTelInputModule,
    DxTagBoxModule

  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    // UserModule,
    SmartadminLayoutModule,
    BootstrapModule,
    I18nModule,
    UtilsModule,
    PipesModule,
    SmartadminFormsLiteModule,
    SmartProgressbarModule,
    InlineGraphsModule,
    SmartadminWidgetsModule,
    StatsModule,
    OrderModule,
    FilterPipeModule,
    NgMultiSelectDropDownModule,
    Ng5SliderModule,
    MatExpansionModule,
    UiComponentsModule,
    DirectivesModule,
    DxDiagramModule,
    DxPopupModule,
    DxTemplateModule,
    DxDataGridModule,
    DxDropDownBoxModule,
    DxTabPanelModule,
    DxTreeViewModule,
    DxRadioGroupModule,
    DxTreeViewModule,
    DxTreeListModule,
    DxSelectBoxModule,
    DxContextMenuModule,
    AngularDraggableModule,
    NgxIntlTelInputModule,
    DxTagBoxModule
  ]
})
export class SharedModule { }
