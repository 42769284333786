import { DatePipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#162F4D",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 50,
  "bgsType": "ball-spin-clockwise-fade-rotating",
  "blur": 1,
  "fgsColor": "#162F4D",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-spin-clockwise-fade-rotating",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.3)",
  "pbColor": "#162F4D",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center"
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule
    , BrowserAnimationsModule
    , AppRoutingModule
    , SharedModule
    , CoreModule
    , ReactiveFormsModule
    , MonacoEditorModule.forRoot()
    , NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
    , NgxUiLoaderRouterModule.forRoot({ showForeground: true, loaderId: ngxUiLoaderConfig.masterLoaderId })
    , NgxUiLoaderHttpModule.forRoot({
      showForeground: true, loaderId: ngxUiLoaderConfig.masterLoaderId,
      exclude: [
        `${environment.BASE_URL}/api/user/notifications`,
        `${environment.BASE_URL}/api/user/currentuser`,
        `${environment.BASE_URL}/api/user/updateAllNotificationsIsRead`,
        `${environment.BASE_URL}/api/user/updateNotificationIsRead`,
        `${environment.BASE_URL}/api/portal/clientsRegistration/progress/log`,
        `${environment.BASE_URL}/api/portal/timeZones`,
        `${environment.BASE_URL}/api/user/timezone`
      ]
    })
  ],
  providers: [DatePipe, TitleCasePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
