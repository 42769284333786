import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { I18nModule } from "@app/shared/i18n/i18n.module";
import { PipesModule } from "@app/shared/pipes/pipes.module";
import { UserModule } from "@app/shared/user/user.module";
import { UtilsModule } from "@app/shared/utils/utils.module";
import { DxContextMenuModule, DxDataGridModule, DxDiagramModule, DxPopupModule, DxTemplateModule } from "devextreme-angular";
import { DxoPagerModule } from "devextreme-angular/ui/nested";
import { ActivitiesComponent, ActivitiesNotificationComponent } from "./activities";
import { ActivitiesNotificationDetailsComponent } from "./activities/activities-notification/activities-notification-details/activities-notification-details.component";
import { CollapseMenuComponent } from "./collapse-menu/collapse-menu.component";
import { FullScreenComponent } from "./full-screen/full-screen.component";
import { HeaderComponent } from "./header.component";
import { UserProfileComponent } from "./profile";
import {PopoverModule} from "ngx-bootstrap/popover";
import {ModalModule} from "ngx-bootstrap/modal";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule,
    UtilsModule,
    PipesModule,
    I18nModule,
    UserModule,
    PopoverModule,
    RouterModule,
    DxDiagramModule,
    DxPopupModule,
    DxoPagerModule,
    DxTemplateModule,
    DxDataGridModule,
    DxContextMenuModule,
    ModalModule.forRoot()
  ],
  declarations: [
    FullScreenComponent,
    CollapseMenuComponent,
    HeaderComponent,
    UserProfileComponent,
    ActivitiesComponent,
    ActivitiesNotificationComponent,
    ActivitiesNotificationDetailsComponent
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
