import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { I18nService } from "@app/shared/i18n/i18n.service";
import * as FileSaver from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import * as XLSX from 'xlsx';
import { AuthService } from "./auth.service";
import { NotificationService } from "./notification.service";
import { StorageService } from "./storage.service";
import { environment } from '@env/environment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class HelperFunctionsService {
  constructor(private notificationService: NotificationService
    , private router: Router
    , private storage: StorageService
    , private authService: AuthService
    , private i18nService: I18nService) {
    try {
      if (environment && environment.GroupSumExcludedFields) {
        this.groupSumExcludedFields = environment.GroupSumExcludedFields;
      }
    } catch (e) {
      this.groupSumExcludedFields = "282, 284, 293, 294";
    }
  }

  public inEditingMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public inEditingMode$: Observable<boolean> = this.inEditingMode.asObservable();

  public fieldModifications: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public fieldModifications$: Observable<any[]> = this.fieldModifications.asObservable();

  public documentModifications: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public documentModifications$: Observable<any[]> = this.documentModifications.asObservable();

  public resetModifications: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public resetModifications$: Observable<any> = this.resetModifications.asObservable();

  public triggerRefreshNotifications: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public triggerRefreshNotifications$: Observable<any> = this.triggerRefreshNotifications.asObservable();

  public triggerToggleNotifications: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  public triggerToggleNotifications$: Observable<any> = this.triggerToggleNotifications.asObservable();

  groupSumExcludedFields: string = "";

  getObjectValueFromArray(object: any, keyName: string, keyValue: string, propertyToReturn: string) {
    if (keyValue == "" || keyValue == null) {
      return '';
    }
    return (object.find(x => x[keyName] == keyValue))[propertyToReturn];
  }

  checkUserAccessLevel(alsoCHeckForViewOnlyAccess = false) {
    // Redirect users with partial access level to the unauthorized page when they try to access a page that
    // they do not have permissions. For now, partial access user can only view the questionnaire form page
    if (this.authService.accessLevel != null && this.authService.accessLevel != 1) {
      this.router.navigate(['/auth/unauthorized']);
    } else if (alsoCHeckForViewOnlyAccess && this.authService.user && this.authService.user['isInViewOnlyMode']) {
      this.router.navigate(['/main/dashboard']);
    }
  }

  hasChanged(form: FormGroup, data: any, mappings: any = {}, nestedPaths?: any, exclusions?: Array<string>): boolean {
    for (var formKey in form.controls) {

      //Check for exclusions
      if (exclusions != undefined && exclusions.includes(formKey)) {
        continue;
      }

      if (nestedPaths != undefined) {
        let nestedPath = formKey in nestedPaths ? nestedPaths[formKey] : null;
        if (nestedPath != null) {
          let objectName = nestedPath.obj;
          let property = nestedPath.prop;
          let fieldValue = form.controls[formKey].value;
          let dataValue = this.isPropertyNullOrEmpty(data[objectName], property);
          if (fieldValue != dataValue) {
            if (fieldValue == '' && dataValue == null || fieldValue == null && dataValue == '') {
              continue;
            }
            return false;
          }
          continue;
        }
      }

      let dataMappingKey = formKey in mappings ? mappings[formKey] : formKey
      let fieldValue = form.controls[formKey].value;
      let dataValue = data[dataMappingKey];
      if (fieldValue != dataValue) {
        if (fieldValue == '' && dataValue == null || fieldValue == null && dataValue == '') {
          continue;
        }
        return false;
      }
    }
    return true;
  }

  formMapper(form: FormGroup, data: any, mappings: any = {}, nestedPaths?: any, exclusions?: Array<string>) {
    for (var formKey in form.controls) {
      //Check for exclusions
      if (exclusions != undefined && exclusions.includes(formKey)) {
        continue;
      }
      if (nestedPaths != undefined) {
        let nestedPath = formKey in nestedPaths ? nestedPaths[formKey] : null;
        if (nestedPath != null) {
          //advanced mapping for when the data property is within an object (depth level == 1)
          let objectName = nestedPath.obj;
          let property = nestedPath.prop;
          let finalValue = this.isPropertyNullOrEmpty(data[objectName], property);
          form.controls[formKey].setValue(finalValue == null ? '' : finalValue);
          continue;
        }
      }
      //normal property to property mapping
      let dataMappingKey = formKey in mappings ? mappings[formKey] : formKey
      form.controls[formKey].setValue(data[dataMappingKey] == null ? '' : data[dataMappingKey]);
    }
  }

  formReset(form: FormGroup) {
    form.markAsUntouched();
    for (var formKey in form.controls) {
      if (typeof form.controls[formKey].value === 'boolean') {
        form.controls[formKey].setValue(false);
      } else {
        form.controls[formKey].setValue('');
      }
    }
  }

  public displayNotificationErrorAlert(error: any) {
    if (error) {
      for (var key in error.error) {
        this.notificationService.smallBox({
          title: this.i18nService.getTranslation(error.error[key].title),
          content: this.i18nService.getTranslation(error.error[key].description) + "<br/>",
          color: "#F08080",
          iconSmall: "fa fa-exclamation-triangle",
          timeout: 6000
        });
      }
    }
  }

  public displayErrorMessage(errorMessage: string) {
    if (errorMessage) {
      this.notificationService.smallBox({
        title: this.i18nService.getTranslation('Error'), // You can customize the error title here
        content: this.i18nService.getTranslation(errorMessage) + "<br/>",
        color: "#F08080",
        iconSmall: "fa fa-exclamation-triangle",
        timeout: 6000
      });
    }
  }

  public displayNotificationSuccessAlert(successTitle: string) {
    this.notificationService.smallBox({
      title: this.i18nService.getTranslation(successTitle),
      content: `<i class='fa fa-clock-o'></i> <i>2 ${this.i18nService.getTranslation('seconds ago')}...</i>`,
      color: "#296191",
      iconSmall: "fa fa-check",
      timeout: 6000
    });
  }

  getCurrentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().substring(0, 10);
  }

  public displayNotificationErrorAlertCustomText(alertTitle: string, alertContent: string, alertColor: string = "#F08080") {

    if (alertColor == "") {
      alertColor = "#F08080";
    }

    this.notificationService.smallBox({
      title: this.i18nService.getTranslation(alertTitle),
      content: this.i18nService.getTranslation(alertContent),
      color: alertColor,
      iconSmall: "fa fa-exclamation-triangle",
      timeout: 6000
    });

  }

  public displayNotificationSuccessAlertCustomText(alertTitle: string, alertContent: string, alertColor: string) {

    if (alertColor == "") {
      alertColor = "#296191";
    }

    this.notificationService.smallBox({
      title: this.i18nService.getTranslation(alertTitle),
      content: this.i18nService.getTranslation(alertContent),
      color: alertColor,
      iconSmall: "fa fa-check",
      timeout: 6000
    });
  }

  public GetUser() {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        USER => {
          resolve(USER);
        },
        error => {
          reject("Error in Helper.GetUser");
        }
      )
    });
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public camelToTitle(text: string) {
    return text.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase());
  }

  //Concept: Instead of performing a turnary for each control, pass it directly to the function.
  // i.e If i want to check for clientDocument.document.name, i will perform isNullOrEmpty(clientDocument.document, "name")
  public isPropertyNullOrEmpty(field: any, property: any) {
    return field && field[property] ? field[property] : ''
  }

  public splitStringToArray(stringValue: string, separator: string) {
    return stringValue.split(",");
  }

  public coloredSelectedStructureTreeNode(profileId: any) {

    var treeStructure = document.querySelectorAll('[id^="profileS-"]');

    for (var i = 0; i < treeStructure.length; i++) {
      treeStructure[i].className = "label custom-text-color-black custom-font-size12";
    }

    var selectedTreeStructure = document.querySelectorAll("[id^=profileS-" + profileId + "]");

    for (var i = 0; i < selectedTreeStructure.length; i++) {
      selectedTreeStructure[i].className = "label label-warning custom-font-size14";
    }

  }

  filterDocumentsByCapacityAndClientCategory(capacityId, portalCapacities, integrationCapacities, documents, clientCategory, hasAdminRights): Observable<any> {
    documents = documents.filter(x => x.visibleInPortal || hasAdminRights);

    if (capacityId != null) {
      let integrationCapacityId = -1;
      let capacityName = capacityId == -2 ? "Shareholder" : capacityId == -3 ? "Self-declaration UBO" : null;

      if (capacityName == null) {
        let matchedCapacities = portalCapacities.filter(x => x.capacityId == capacityId);

        if (matchedCapacities.length != 0) {
          capacityName = matchedCapacities[0].name;
        }
      }

      let matchedIntegrationCapacities = integrationCapacities.filter(x => x.capacityName == capacityName);

      if (matchedIntegrationCapacities.length != 0) {
        integrationCapacityId = matchedIntegrationCapacities[0].id;
      }

      documents = documents.filter(x => x.documentCapacities.includes(integrationCapacityId) || x.documentCapacities.length == 0);
    }
    if (clientCategory != null && clientCategory != -1) {
      documents = documents.filter(x => x.documentClientCategories.includes(clientCategory) || x.documentClientCategories.length == 0 || clientCategory == -1);
    }

    return Observable.of(documents);
  }

  isValidEmailAddress(email: string): boolean {
    var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    return regexEmail.test(email);
  }

  getSymbolByValidationType(validationType) {
    switch (validationType) {
      // Greater than
      case 1: {
        return ">";
      }
      // Greater than or equal to
      case 2: {
        return ">=";
      }
      // Less than
      case 3: {
        return "<";
      }
      // Less than or equal to
      case 4: {
        return "<=";
      }
      // Equal
      case 5: {
        return "==";
      }
      // Not equal
      case 6: {
        return "!=";
      }
    }
  }

  scrollToElement(childId, sectionsWrapper = null) {
    var child = $(`#${childId}`);
    if (sectionsWrapper && child.length > 0) {
      const fieldType = child.attr("type");

      if (["text", "number"].includes(fieldType)) {
        setTimeout(function () {
          try {
            child.focus();
            child[0].focus();
          } catch (_) {
            document.getElementById(child.id).focus();
          }
        }, 500);
      } else if (fieldType == "radio") {
        let radioLabelToFocus = child.closest('label.radio');
        this.innerDivScroll(radioLabelToFocus[0], sectionsWrapper);
      }
      // Dropdowns(single/multi) that cannot be focused
      else {
        let dropdownElement = document.getElementById(childId);
        this.innerDivScroll(dropdownElement, sectionsWrapper);
      }

      // Show o border around the field in order for the user to see it
      child.closest('.c-field-wrapper').addClass('d-border');
      setTimeout(function () {
        child.closest('.c-field-wrapper').removeClass('d-border');
      }, 6000);
    }
  }

  innerDivScroll(childDiv, parentDiv) {
    var top = parseInt(parentDiv.getBoundingClientRect().top);
    var bot = parseInt(parentDiv.getBoundingClientRect().bottom);

    var nowTop = childDiv.getBoundingClientRect().top;
    var nowBot = childDiv.getBoundingClientRect().bottom;

    var scroll_by = 0;
    if (nowTop < top)
      scroll_by = -(top - nowTop);
    else if (nowBot > bot)
      scroll_by = nowBot - bot;
    if (scroll_by != 0) {
      parentDiv.scrollTop += scroll_by + 100;
    }
  }

  getSumOfFieldsByGroup(groupId, dynamicFields) {
    let groupSumExcludedFields = this.groupSumExcludedFields || "282, 284, 293, 294";

    let groupSumExcludedFieldsArray = groupSumExcludedFields.split(",").map(Number);

    const numberFields = dynamicFields.filter(x => !groupSumExcludedFieldsArray.includes(x.id) && x.groupId == groupId && x.isVisible && x.fieldType == 1);
    return numberFields.length == 0 ? 0 : numberFields.map(field => (field.answer == "" || field.answer == null) ? 0 : parseInt(field.answer)).reduce((a, b) => a + b) || 0;
  }

  copyToClipboard(string: string, showAlert = true) {
    if (typeof (string) == "object") {
      string = JSON.stringify(string);
    }
    const _this = this;

    window.navigator['clipboard'].writeText(string).then(function () {
      if (showAlert) {
        _this.displayNotificationSuccessAlertCustomText("Copied to clipboard", "", "");
      }
    }, function () {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = string;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    });
  }

  encodeSectionName(sectionName: string): string {
    return sectionName ? sectionName.replace(/\s/g, '-') : sectionName;
  }

  goToProfile(row, clientRegistrationPhaseId, sectionName, route: ActivatedRoute) {
    const routeParam = row.clientType == 1 ? 'client-individual' : 'client-corporate';

    this.router.navigate([`/main/${routeParam}`, row.currentPhaseId], {
      queryParams: {
        hrc: this.getRouteHrc(route, clientRegistrationPhaseId),
        parentSection: this.encodeSectionName(sectionName)
      }
    });
  }

  getRouteHrc(route: ActivatedRoute, clientRegistrationPhaseId): string {
    let hrc = route.snapshot.queryParamMap.get('hrc') as any;

    if (hrc) {
      hrc = JSON.parse(hrc);
    } else {
      hrc = [];
    }

    clientRegistrationPhaseId = typeof (clientRegistrationPhaseId) == "string" ? Number(clientRegistrationPhaseId) : clientRegistrationPhaseId;
    hrc.push(clientRegistrationPhaseId)
    return JSON.stringify(hrc);
  }

}
