import { Component } from '@angular/core';
import { ApiService } from './core/services';
import DataGrid from "devextreme/ui/data_grid";
import drop_down_box from "devextreme/ui/drop_down_box";

@Component({
  selector: 'app-root',
  template: '<ngx-ui-loader></ngx-ui-loader><div ngxUiLoaderBlurred><router-outlet></router-outlet></div>',
})
export class AppComponent {
  title = 'sa';

  solutionName: any;
  favicon: any;

  dropDownDefaultOptions: any = {
    device: { deviceType: "desktop" },
    options: {
      stylingMode: "outlined",
      height: 34
    }
  };

  dataGridDefaultOptions: any = {
    device: { deviceType: "desktop" },
    options: {
      stylingMode: "outlined",
      columnChooser: {
        allowSearch: true,
        height: 350,
        width: 300,
        mode: "select"
      }
    }
  };

  constructor(private apiService: ApiService) {
    this.initializeDefaultDxOptions();

    const getGuestAccountService = this.apiService.getGuestPortalAccount()
      .subscribe(
        data => {
          this.solutionName = data.name;
          this.favicon = data.favicon;

          if (data.hubId != null) {
            $(document.body).append(`<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/${data.hubId}.js"></script>`);
          }

          $("#pageTitle").text(this.solutionName);
          $("#faviconId").attr("href", `data:image/png;base64,${this.favicon}`);
        },
        error => {
          $("#pageTitle").text("RegTek");
          getGuestAccountService.unsubscribe();
        });
  }

  initializeDefaultDxOptions() {
    DataGrid.defaultOptions(this.dataGridDefaultOptions);
    drop_down_box.defaultOptions(this.dropDownDefaultOptions);
  }
}
